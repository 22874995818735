<template>
    <b-container v-if="kprRows" fluid>
        <b-row class="flex-center my-2">
            <b-col>
                <table class="table b-table table-striped table-hover table-bordered text-center align-middle">
                    <thead>
                    <tr>
                        <th></th>
                        <th>
                            Obrazac KPR-1041 <br/>
                            <h3>Knjiga prihoda i rashoda za period <u>{{$cookie.get('ezint-year')}}</u></h3>
                        </th>
                        <th>Stranica {{kprRows.current_page}} od  {{kprRows.last_page}}</th>
                    </tr>
                    </thead>
                </table>
            </b-col>
        </b-row>

        <b-row class="justify-content-center text-left my-2">
            <b-col>
                <table class="table b-table table-striped table-hover table-bordered text-left align-middle">
                    <thead>
                    <tr>
                        <th>Podaci o poreznom obvezniku ili nosiocu zajedničke djelatnosti: </th>
                        <th>Podaci o registrovanoj djelatnosti: </th>
                    </tr>
                    <tr>
                        <td><b>1. JMB: </b>{{selectedClient.owner.jmbg}}</td>
                        <td><b>4. JIB: </b>{{selectedClient.id_number}}</td>
                    </tr>
                    <tr>
                        <td><b>2. Prezime i ime:  </b>{{selectedClient.owner.name}}</td>
                        <td><b>5. Naziv: </b>{{selectedClient.name}}</td>
                    </tr>
                    <tr>
                        <td><b>3. Adresa sjedišta:  </b>{{selectedClient.owner.address}}</td>
                        <td><b>6. Adresa prebivališta: </b>{{selectedClient.address}}</td>
                    </tr>
                    </thead>
                </table>
            </b-col>
        </b-row>

        <b-row class="flex-center my-4">
            <b-col>
                <h5>Podaci o prihodima/rashodima koji su naplaćeni/isplaćeni</h5>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <roza-table :items="kprRows"
                            :fields="kprFormFields"
                            :prenosFields="prenosFields"
                            :prenosColspan="4"
                            :colIndex="7"
                            :per-page="perPage"
                            :current-page="currentPage"
                            class="table-sm"
                            :actions="actions"
                            @action="action">
                </roza-table>

                <b-pagination align="right"
                              @input="getRows"
                              :total-rows="kprRows.total"
                              v-model="currentPage"
                              :per-page="perPage">
                </b-pagination>
            </b-col>
        </b-row>

        <set-index-modal
                ref="setIndex"
                index="kpr_index"
                type="manual"
                :item="selectedRow">
        </set-index-modal>
    </b-container>
</template>

<script>
    export default {
        name: 'ShowKprForm',
        data() {
            return {
                kprForm: null,
                kprRows: null,
                kprFormFields: [
                    {
                        key: 'kpr_index',
                        label: 'Broj'
                    },
                    {
                        key: 'document_date',
                        label: 'Datum prihoda/rashoda',
                        date: true
                    },
                    {
                        key: 'document_number',
                        label: 'Broj dokumenta'
                    },
                    {
                        key: 'description',
                        label: 'Opis dokumenta'
                    },
                    {
                        key: 'total_cash_revenues',
                        label: 'Prihodi u gotovini',
                        class: 'text-right'
                    },
                    {
                        key: 'total_bank_revenues',
                        label: 'Preko bankovnog računa',
                        class: 'text-right'
                    },
                    {
                        key: 'total_services_revenues',
                        label: 'U stvarima i uslugama',
                        class: 'text-right'
                    },
                    {
                        key: 'total_tax_revenues',
                        label: 'PDV u prihodima',
                        class: 'text-right'
                    },
                    {
                        key: 'total_revenues',
                        label: 'Ukupni prihodi',
                        class: 'text-right'
                    },
                    {
                        key: 'total_goods_expenses',
                        label: 'Rashodi u robi/materijalu',
                        class: 'text-right'
                    },
                    {
                        key: 'total_gross_salary',
                        label: 'Bruto plaće zaposlenika',
                        class: 'text-right'
                    },
                    {
                        key: 'total_paid_contributions',
                        label: 'Plaćeni doprinosi poduzetnika',
                        class: 'text-right'
                    },
                    {
                        key: 'total_other',
                        label: 'Ostalo',
                        class: 'text-right'
                    },
                    {
                        key: 'total_tax_expenses',
                        label: 'PDV u rashodima',
                        class: 'text-right'
                    },
                    {
                        key: 'total_expenses',
                        label: 'Ukupni rashodi',
                        class: 'text-right'
                    }
                ],
                prenosFields: [
                    'cashRevenuesSum',
                    'bankRevenuesSum',
                    'servicesRevenuesSum',
                    'taxRevenuesSum',
                    'revenuesSum',
                    'goodsExpensesSum',
                    'grossSalarySum',
                    'paidContributionsSum',
                    'otherSum',
                    'taxExpensesSum',
                    'expensesSum'
                ],
                actions: {
                    index: {
                        name: 'Dodijeli redni br.',
                        icon: 'fa fa-hashtag'
                    }
                },

                currentPage: 1,
                perPage: 20,
                selectedRow: null
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            getRows() {
                axiOsta.get(api.clients + this.selectedClient.id + api.kprRows, {
                    params: {
                        page: this.currentPage,
                        perPage: this.perPage,
                        year: this.$cookie.get('ezint-year')
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.kprRows = response.data;
                    }
                })
            },
            action: function (action, item) {
                switch (action) {
                    case 'index':
                        this.selectedRow = item;
                        this.$refs.setIndex.show();
                        break;
                }
            },
        },
        mounted() {
            this.$on('get-rows', () => this.getRows())
        },
        created() {
            if (this.selectedClient != null) {
                this.getRows();
            }
            eventBus.$on('year-changed', () => {
                this.getRows();
            });
        },
        watch: {
            selectedClient: function (newValue, oldValue) {
                if (oldValue == null) {
                    this.getRows();
                }
            },
        }
    }
</script>
